import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import { StaticImage } from "gatsby-plugin-image"

export default function Job({ data, pageContext }) {
  // = = = = = = = = = = = = =
  // Data
  const { title } = pageContext

  const { hasNav, innerNav, background } = data.jobPage.nodes[0].components[0]

  const { role, location, fullContent, description, typeOfPosition, applicationLink } =
    data.job.nodes[0]

  const heroData = {
    hasNav: hasNav,
    headline: title,
    heroSize: "small",
    innerNav: innerNav,
    hasBreadcrumb: false,
    background: background,
    intro: `${location} | ${typeOfPosition}`,
  }

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={data?.page?.nodes[0].metadata?.description}
          image={data?.page?.nodes[0].metadata?.image}
        />

        <Hero key={heroData.headline} component={heroData}>
          <StaticImage
            alt=""
            className="hero__bg"
            src="../../src/images/job-hero__bg.jpg"
          />
        </Hero>

        <section className="job">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div
                  className="job__intro"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div
                  className="job__content"
                  dangerouslySetInnerHTML={{ __html: fullContent }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="buttons-wrapper">
                  {
                    applicationLink
                      &&
                    <a className="btn btn-primary" rel="noopener noreferrer" target="_blank" href={applicationLink}>
                      Go to application
                    </a>
                  }
                  <Link className="btn btn--job" to="/jobs">
                    Back to all jobs
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer showFooterForm={true}/>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    job: allDatoCmsJob(filter: { slug: { eq: $slug } }) {
      nodes {
        datePosted(formatString: "MMMM DD, YYYY")
        role
        title
        location
        fullContent
        description
        organization
        typeOfPosition
        applicationLink
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
      }
    }
    jobPage: allDatoCmsPage(filter: { pageTitle: { eq: "Jobs" } }) {
      nodes {
        components {
          ... on DatoCmsHeroHeader {
            hasNav
            background {
              image {
                gatsbyImageData
              }
              backgroundColor
              hasBackgroundImage
            }
            innerNav {
              links {
                linkUrl
                label
                isThisAButton
              }
            }
          }
        }
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
      }
    }
  }
`
