import "./index.scss"
import React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export default function Hero({ component }) {
  const {
    intro,
    hasNav,
    headline,
    heroSize,
    innerNav,
    breadcrumb,
    hasBreadcrumb,
  } = component

  const { image, backgroundColor, hasBackgroundImage } = component.background[0]
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const bgImage = hasBackgroundImage ? getImage(image) : null

  return (
    <div
      className={`hero ${heroSize}`}
      style={{
        backgroundColor:
          hasBackgroundImage === false ? backgroundColor : "#fff",
      }}
    >
      {hasBackgroundImage && (
        <div className="hero__bg">
          <GatsbyImage image={bgImage} alt="" />
        </div>
      )}
      <div className="container">
        {hasBreadcrumb && (
          <div className="row hero__breadcrumb">
            <div className="col justify-content-start">
              <Link to={breadcrumb[0].linkUrl}>‹ {breadcrumb[0].label}</Link>
            </div>
          </div>
        )}
        {hasNav && (
          <div className="row hero__nav">
            <div className="col justify-content-end">
              <ul>
                {innerNav[0]?.links?.map(item => {
                  return (
                    <li>
                      <Link aria-current={ url?.includes(item.linkUrl) } to={item.linkUrl}>{item.label}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12">
            <h1 className="text-white oblique">{headline}</h1>
          </div>
        </div>
        {intro && (
          <div className="row">
            <div className="col-lg-8">
              <p className="intro">{intro}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
